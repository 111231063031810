<template>
    <el-container class="main-container" style="height: calc(100vh - 60px)">
        <el-row :gutter="20" style="height: 60%">
            <el-col :span="14">
                <el-card title="图片上传情况" style="width: 60%; height: 100%">
                    <div slot="header" class="clearfix">
                        <span>系统信息</span>
                    </div>
                    <el-tabs>
                        <el-tab-pane label="图片上传信息">
                            <el-tabs v-model="appImageInfoActiveName">
                                <el-tab-pane label="最近一天" name="day">
                                    <el-result icon="success" :title="dayTotal">
                                        <template slot="extra">
                                            <el-button
                                                type="primary"
                                                size="medium"
                                                @click="getAppImageInfo"
                                                >刷新</el-button
                                            >
                                        </template>
                                    </el-result>
                                </el-tab-pane>
                                <el-tab-pane label="最近一周" name="weak">
                                    <el-result
                                        icon="success"
                                        :title="weakTotal"
                                    >
                                        <template slot="extra">
                                            <el-button
                                                type="primary"
                                                size="medium"
                                                @click="getAppImageInfo"
                                                >刷新</el-button
                                            >
                                        </template>
                                    </el-result>
                                </el-tab-pane>
                                <el-tab-pane label="最近一月" name="month">
                                    <el-result
                                        icon="success"
                                        :title="monthTotal"
                                    >
                                        <template slot="extra">
                                            <el-button
                                                type="primary"
                                                size="medium"
                                                @click="getAppImageInfo"
                                                >刷新</el-button
                                            >
                                        </template>
                                    </el-result>
                                </el-tab-pane>
                            </el-tabs>
                        </el-tab-pane>
                        <el-tab-pane label="分类信息">
                            <el-card>
                                <el-tag
                                    type="primary"
                                    v-for="(item, index) in classifies"
                                    :class="index > 0 ? 'ml20' : ''"
                                    :key="item.value"
                                >
                                    {{ item.text }}
                                </el-tag>
                            </el-card>
                        </el-tab-pane>
                        <el-tab-pane label="标签信息">
                            <el-card>
                                <el-tag
                                    type="success"
                                    v-for="(item, index) in tags"
                                    :class="index > 0 ? 'ml20' : ''"
                                    :key="item.value"
                                >
                                    {{ item.text }}
                                </el-tag>
                            </el-card>
                        </el-tab-pane>
                    </el-tabs>
                </el-card>
            </el-col>
            <el-col :span="10">
                <el-row>
                    <el-col :span="24">
                        <el-card style="changeLog">
                            <div slot="header" class="clearfix">
                                <span>更新日志</span>
                            </div>
                            <v-md-preview :text="changeLog"></v-md-preview>
                        </el-card>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <el-row class="mt20 pt20" style="height: 39%; width: 57.5%;">
            <el-col :span="24">
                <el-card>
                    <div slot="header" class="clearfix">
                        <span>用户反馈信息</span>
                        <el-button
                            style="float: right; padding: 3px 0"
                            type="text"
                            @click="refreshAppUserFeedBackList"
                            >刷新</el-button
                        >
                    </div>
                    <div
                        id="feed-back-list"
                        v-feed-back-list-loadMore="getAppUserFeedBackInfo"
                    >
                        <el-timeline
                            v-for="(item, index) in feedbackList"
                            :key="index"
                        >
                            <el-timeline-item
                                :hide-timestamp="true"
                                placement="top"
                                size="large"
                                color="#0bbd87"
                            >
                                <el-card style="width: 95% !important">
                                    <h4>{{ item.content }}</h4>
                                    <p>
                                        {{
                                            item.userName === ''
                                                ? item.userId
                                                : item.userName
                                        }}
                                        提交于 {{ item.time }}
                                    </p>
                                </el-card>
                            </el-timeline-item>
                        </el-timeline>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </el-container>
</template>

<script>
export default {
    directives: {
        'feed-back-list-loadMore': {
            bind(el, binding) {
                el.addEventListener('scroll', function () {
                    const condition =
                        this.scrollHeight - this.scrollTop <= this.clientHeight;
                    if (condition) {
                        binding.value();
                    }
                });
            },
        },
    },
    data() {
        return {
            appImageInfoActiveName: 'day',
            dayTotal: '',
            weakTotal: '',
            monthTotal: '',
            classifies: [],
            tags: [],
            feedbackList: [],
            query: {
                page: 1,
                size: 10,
            },
            changeLog: ''
        };
    },
    created() {
        this.getAppImageInfo();
        this.getOptions();
        this.getAppUserFeedBackInfo();
        this.getChangeLog()
    },
    methods: {
        async getAppImageInfo() {
            const { data: res } = await this.$api.getAppImageInfo();
            if (res.code > 0) {
                return this.$notify.error({
                    title: '错误',
                    message: res.message,
                });
            }
            this.dayTotal = `上传成功 ${res.data.dayTotal} 张`;
            this.weakTotal = `上传成功 ${res.data.weakTotal} 张`;
            this.monthTotal = `上传成功 ${res.data.monthTotal} 张`;
        },
        async getOptions() {
            const { data: res } = await this.$api.getOptions();
            if (res.code > 0) {
                return this.$notify.error({
                    title: '错误',
                    message: res.message,
                });
            }
            this.classifies = res.data.appClassifies;
            this.tags = res.data.appTags;
        },
        async getAppUserFeedBackInfo() {
            const { data: res } = await this.$api.getAppUserFeedBackInfo(
                this.query
            );
            if (res.code > 0) {
                return this.$notify.error({
                    title: '错误',
                    message: res.message,
                });
            }
            this.feedbackList = [...this.feedbackList, ...res.data];
            this.query.page++;
        },
        async getChangeLog() {
            const { data: res } = await this.$api.getChangeLog();
            if (res.code > 0) {
                return this.$notify.error({
                    title: '错误',
                    message: res.message,
                });
            }
            this.changeLog = res.data
        },
        refreshAppUserFeedBackList() {
            this.query.page = 1;
            this.feedbackList = [];
            this.getAppUserFeedBackInfo();
        },
    },
};
</script>

<style scoped>
.el-card {
    width: 100% !important;
}

#feed-back-list {
    height: var(--feed-back-card-height);
    overflow-y: visible;
    width: 100%;
    overflow-x: hidden;
}
.half-height {
    height: calc(50vh - 30px);
}

.changeLog {
  height: calc(100vh - 100px);
}
</style>
